<template>
  <div>
    <backupprocessing v-if="success"></backupprocessing>
    <div v-else>
      <backuplist v-if="!backup" @save="(b) => (backup = b)"></backuplist>
      <backuprestore
        v-if="backup"
        :backup="backup"
        @close="() => (backup = false)"
        @save="() => (success = true)"
      ></backuprestore>
    </div>
  </div>
</template>
<script>
export default {
  name: 'backupComponent',
  components: {
    backupprocessing() {
      return import('./processing.vue')
    },
    backuplist() {
      return import('./list.vue')
    },
    backuprestore() {
      return import('./restore.vue')
    }
  },
  props: {},
  data() {
    return {
      backup: false,
      success: false
    }
  },
  computed: {},
  mounted() {},
  methods: {}
}
</script>
<style lang="sass" scoped></style>
